import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import styles from '../features/Chat/styles/chat.module.css';
import { CopyButton } from '../features/Chat/components/Message';

export const remarkPlugins = [remarkGfm, remarkMath];
export const rehypePlugins = [
  rehypeRaw, 
  rehypeKatex, 
  [rehypeHighlight, { detect: true }]
];

export const markdownComponents = {
  code({ node, inline, className, children, ...props }) {
    const match = /language-(\w+)/.exec(className || '');
    const language = match ? match[1] : '';
    
    if (!inline && match) {
      return (
        <pre style={{ margin: 0, padding: 0 }}>
          <div className={styles.codeHeader}>
            <span className={styles.language}>{language}</span>
            <CopyButton code={children} />
          </div>
          <div className={styles.codeBlock}>
            <pre className={className}>
              <code className={className} {...props}>
                {children}
              </code>
            </pre>
          </div>
        </pre>
      );
    }

    return (
      <code className={className} {...props}>
        {children}
      </code>
    );
  }
};