import React, { useState, useRef, useEffect } from 'react';
import { FaHistory } from 'react-icons/fa';
import { IoAddCircleOutline } from 'react-icons/io5';
import { BsThreeDotsVertical } from 'react-icons/bs';
import styles from '../styles/chat.module.css';
import { AuthModal, useAuth } from '../../Auth';

const Header = ({ toggleSessionList, startNewSession, isSessionListVisible }) => {
  const { isAuthenticated, user, logout } = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const sessionListRef = useRef(null);
  const historyButtonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close dropdown if click is outside both the button and dropdown
      if (
        isDropdownOpen &&
        !buttonRef.current?.contains(event.target) &&
        !dropdownRef.current?.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }

      // Close session list if click is outside both the history button and session list
      if (
        isSessionListVisible &&
        !historyButtonRef.current?.contains(event.target) &&
        !sessionListRef.current?.contains(event.target)
      ) {
        toggleSessionList();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isDropdownOpen, isSessionListVisible, toggleSessionList]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleAuthClick = () => {
    setShowAuthModal(true);
    setIsDropdownOpen(false);
  };

  return (
    <div className={styles.header}>
      <div className={styles.leftButtons}>
        <button 
          className={styles.headerButton} 
          onClick={toggleSessionList}
          aria-label="Toggle history"
          ref={historyButtonRef}
        >
          <FaHistory size={20} />
        </button>
        {isSessionListVisible && (
          <div className={styles.sessionList} ref={sessionListRef}>
            <ul>
              <li>Session 1</li>
              <li>Session 2</li>
              <li>Session 3</li>
            </ul>
          </div>
        )}
        <button 
          className={styles.headerButton} 
          onClick={startNewSession}
          aria-label="New session"
        >
          <IoAddCircleOutline size={22} />
        </button>
      </div>
      <h2>Infer.ing</h2>
      <div style={{ position: 'relative' }}>
        <button 
          className={styles.threeDotButton} 
          onClick={toggleDropdown}
          ref={buttonRef}
        >
          <BsThreeDotsVertical size={20} />
        </button>
        {isDropdownOpen && (
          <ul className={styles.popupDropdown} ref={dropdownRef}>
            <li>Option 1</li>
            <li>Option 2</li>
            <li className={styles.authOption} onClick={handleAuthClick}>
              Login/Register
            </li>
          </ul>
        )}
      </div>
      {showAuthModal && <AuthModal onClose={() => setShowAuthModal(false)} />}
    </div>
  );
};

export default Header;
