import React, { useState } from 'react';
import styles from '../styles/auth.module.css';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';

const AuthModal = ({ onClose }) => {
  const [isLogin, setIsLogin] = useState(true);
  
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modalContent}>
        {/* Header */}
        <div className={styles.modalHeader}>
          <img 
            src={process.env.PUBLIC_URL + '/favicon.ico'} 
            alt="Logo" 
            className={styles.logo} 
          />
          <button className={styles.closeButton} onClick={onClose}>&times;</button>
        </div>

        {/* Tabs */}
        <div className={styles.authTabs}>
          <button 
            className={`${styles.tabButton} ${isLogin ? styles.active : ''}`}
            onClick={() => setIsLogin(true)}
          >
            Login
          </button>
          <button 
            className={`${styles.tabButton} ${!isLogin ? styles.active : ''}`}
            onClick={() => setIsLogin(false)}
          >
            Register
          </button>
        </div>

        {/* Social Auth */}
        <div className={styles.socialAuth}>
          <button className={styles.socialButton} id="googleAuth" name="googleAuth">
            <FcGoogle size={20} />
            Continue with Google
          </button>
          <button className={styles.socialButton} id="facebookAuth" name="facebookAuth">
            <FaFacebook size={20} color="#1877F2" />
            Continue with Facebook
          </button>
        </div>

        <div className={styles.divider}>
          <span>OR</span>
        </div>

        {/* Form Fields */}
        <form className={styles.form}>
          <input 
            type="email" 
            id="email"
            name="email"
            autoComplete="email"
            placeholder="Email" 
            className={styles.input}
          />
          <input 
            type="password" 
            id="password"
            name="password"
            autoComplete="current-password"
            placeholder="Password" 
            className={styles.input}
          />
          
          {isLogin && (
            <div className={styles.formFooter}>
              <label className={styles.checkboxLabel}>
                <input 
                  type="checkbox" 
                  id="rememberMe"
                  name="rememberMe"
                  autoComplete="remember-me"
                  className={styles.checkbox} 
                />
                Remember me
              </label>
              <a href="/forgot-password" className={styles.forgotPassword}>
                Forgot Password?
              </a>
            </div>
          )}

          <button type="submit" name="submitButton" className={styles.submitButton}>
            {isLogin ? 'Login' : 'Create Account'}
          </button>
        </form>

        {/* Footer */}
        <div className={styles.modalFooter}>
          <small>
            By continuing, you agree to our 
            <a href="/terms">Terms of Service</a> and 
            <a href="/privacy">Privacy Policy</a>
          </small>
        </div>
      </div>
    </div>
  );
};

export default AuthModal; 