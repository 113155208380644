// Helper functions for message processing and text manipulation

import { unified } from 'unified'
import remarkParse from 'remark-parse'
import remarkStringify from 'remark-stringify'
import { visit } from 'unist-util-visit'

// A custom plugin to manipulate the AST to achieve similar effects
function remarkAdjustFormatting() {
  return (tree) => {
    // Add spacing before headings and lists by inserting empty paragraphs
    let { children } = tree;
    for (let i = children.length - 1; i >= 1; i--) {
      const node = children[i];
      if (node.type === 'heading' || node.type === 'list') {
        // Insert an empty paragraph before the heading/list for spacing
        children.splice(i, 0, { type: 'paragraph', children: [] });
      }
    }

    // Ensure proper spacing around links
    // We can visit every link and try to ensure it stands out by using line breaks.
    // This is a bit simplistic and may need adjustment depending on your exact requirements.
    visit(tree, 'link', (node, index, parent) => {
      if (!parent || parent.type !== 'paragraph') return;
      // Replace the entire paragraph with a paragraph that has a line break before and after the link.
      // You can adjust this logic as needed.
      parent.children = [
        { type: 'text', value: '\n' },
        node,
        { type: 'text', value: '\n' }
      ];
    });
  };
};

// The main function using unified
export const processContent = (text) => {
  if (!text) return '';

  const file = unified()
    .use(remarkParse)
    .use(remarkAdjustFormatting)
    .use(remarkStringify, { fences: true }) // ensure code blocks remain fenced
    .processSync(text);

  return String(file);
};

export const extractTextFromChildren = (children) => {
  if (!children) return '';

  if (typeof children === 'string') {
    return children;
  }

  if (Array.isArray(children)) {
    return children.map(extractTextFromChildren).join('');
  }

  if (children?.props?.children) {
    if (children.type === 'code') {
      // Handle code blocks
      return children.props.children.toString();
    } else {
      return extractTextFromChildren(children.props.children);
    }
  }

  return String(children).replace(/\[object Object\]/g, '');
};