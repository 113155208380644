import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/chat.module.css';
import Message from './Message';

const MessageList = ({ messages, isLoading, messagesEndRef, messageListRef }) => {
  return (
    <div className={styles.messageList} ref={messageListRef}>
      {messages.map((message, index) => (
        <Message 
          key={message.id || index}
          message={message}
          isLoading={isLoading && index === messages.length - 1}
        />
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

MessageList.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      content: PropTypes.string.isRequired,
      role: PropTypes.oneOf(['user', 'assistant']).isRequired,
      isError: PropTypes.bool
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  messagesEndRef: PropTypes.oneOfType([
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  messageListRef: PropTypes.oneOfType([
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

MessageList.defaultProps = {
  isLoading: false,
  messages: []
};

export default MessageList; 