import React, { memo, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import cx from 'classnames';
import styles from '../styles/chat.module.css';
import { remarkPlugins, rehypePlugins, markdownComponents } from '../../../utils/markdownConfig';
import { processContent, extractTextFromChildren } from '../utils';
import { FaRegCopy } from 'react-icons/fa';

export const CopyButton = ({ code }) => {
  const handleCopy = async () => {
    try {
      let textContent = '';
      
      // If code is a DOM element (pre/code), get its text content directly
      if (code?.props?.children?.props?.children) {
        const codeContent = code.props.children.props.children;
        textContent = Array.isArray(codeContent) 
          ? codeContent.join('') 
          : String(codeContent);
      } else {
        // Fallback to previous method
        textContent = typeof code === 'string' 
          ? code 
          : extractTextFromChildren(code);
      }
      
      // Clean up the text while preserving structure
      const cleanText = textContent
        .replace(/\[object Object\]/g, '')
        .trim();
        
      await navigator.clipboard.writeText(cleanText);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <button 
      className={styles.copyButton}
      onClick={handleCopy}
      aria-label="Copy code"
    >
      <FaRegCopy size={16} />
    </button>
  );
};

const Message = memo(({ message, isLoading }) => {
  const content = message.content || '';
  const showLoading = isLoading && message.role === 'assistant' && !content;
  const isError = message.isError;

  // Memoize class names for better performance
  const wrapperClassName = useMemo(() => cx(
    styles.messageWrapper,
    message.role === 'user' ? styles.userWrapper : styles.assistantWrapper
  ), [message.role]);

  const messageClassName = useMemo(() => cx(
    styles.message,
    message.role === 'user' ? styles.userMessage : 
    isError ? styles.errorMessage : styles.assistantMessage
  ), [message.role, isError]);

  return (
    <div className={wrapperClassName}>
      <div className={messageClassName}>
        <div className={styles.messageContent}>
          {showLoading ? (
            <div className={styles.loadingDots}>
              <span>.</span><span>.</span><span>.</span>
            </div>
          ) : (
            <ReactMarkdown 
              rehypePlugins={rehypePlugins}
              remarkPlugins={remarkPlugins}
              components={markdownComponents}
            >
              {processContent(content)}
            </ReactMarkdown>
          )}
        </div>
      </div>
    </div>
  );
});

Message.displayName = 'Message';

export default Message; 