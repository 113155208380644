export class StreamParser {
  constructor() {
    this.decoder = new TextDecoder();
    this.buffer = '';
  }

  parseChunk(chunk) {
    const text = this.decoder.decode(chunk, { stream: true });
    this.buffer += text;
    
    const messages = [];
    const lines = this.buffer.split('\n');
    
    // Keep the last incomplete line in buffer
    this.buffer = lines.pop() || '';

    for (const line of lines) {
      const trimmedLine = line.trim();
      if (!trimmedLine || !trimmedLine.startsWith('data: ')) continue;
      
      const data = trimmedLine.slice(6);
      if (data === '[DONE]') continue;

      try {
        const parsed = JSON.parse(data);
        messages.push(parsed);
      } catch (error) {
        console.warn('Failed to parse message:', error);
        messages.push({ error: 'Invalid message format' });
      }
    }

    return messages;
  }

  cleanup() {
    this.buffer = '';
  }
} 