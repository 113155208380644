import React from 'react';
import styles from '../styles/chat.module.css';

const MessageInput = ({ input, setInput, handleSubmit, isLoading }) => {
  return (
    <form onSubmit={handleSubmit} className={styles.inputForm}>
      <input
        type="text"
        name="chatMessage"
        id="chatMessage"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        className={styles.input}
        placeholder="Type your message..."
        disabled={isLoading}
      />
      <button
        type="submit"
        className={styles.button}
        disabled={isLoading}
      >
        {isLoading ? 'Sending...' : 'Send'}
      </button>
    </form>
  );
};

export default MessageInput; 