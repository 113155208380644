import { useCallback } from 'react';
import { StreamParser } from '../utils/streamParser';

export const useMessageStream = () => {
  const processStream = useCallback(async (response, onChunk, onError) => {
    if (!response?.body) {
      onError(new Error('Invalid response stream'));
      return;
    }

    const streamParser = new StreamParser();

    try {
      const reader = response.body.getReader();
      
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const messages = streamParser.parseChunk(value);
        
        for (const message of messages) {
          if (message.error) {
            onError(message.error);
            continue;
          }
          
          if (message.content !== undefined) {
            onChunk(message.content);
          }
        }
      }
    } catch (error) {
      onError(new Error(`Stream processing failed: ${error.message}`));
    } finally {
      streamParser.cleanup();
    }
  }, []);

  return { processStream };
}; 