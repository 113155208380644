import React from 'react';
import Chat from './features/Chat';
import { ChatProvider } from './features/Chat/contexts/ChatContext';
import { AuthProvider } from './features/Auth';
import './styles/global.css';
import 'highlight.js/styles/github.css';

function App() {
  return (
    <AuthProvider>
      <ChatProvider>
        <Chat />
      </ChatProvider>
    </AuthProvider>
  );
}

export default App; 